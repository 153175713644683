import { App, Employee } from '../interfaces/Employee'

export const formatCurrencyWithSymbol = (
  value: number,
  locale = 'pt-BR',
  currency = 'BRL',
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value)
}

export const moneyMask = (
  // eslint-disable-next-line no-undef
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const element = e.target as HTMLInputElement

  const valorFormatado = formatCurrencyWithSymbol(
    parseFloat(onlyNumbers(element.value)) / 100,
  )

  element.value = valorFormatado.replace('NaN', '')
}

export const onlyNumbers = (value: string) => {
  return value.replace(/\D/g, '')
}

export const moneyOnlyNumbersMask = (
  // eslint-disable-next-line no-undef
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const element = e.target as HTMLInputElement

  const valorFormatado = formatCurrencyWithSymbol(
    parseFloat(onlyNumbers(element.value)) / 100,
  )

  element.value = valorFormatado.replace('NaN', '')
  element.value = valorFormatado.replace('R$', '')
}

export function convertToKebabCase(string: string) {
  return string
    .replaceAll(' ', '')
    .trim()
    .normalize('NFD')
    .replace(/\p{Mn}/gu, '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase()
}

export const handleLastLoginStatus = (employee: Employee | App) => {
  const lastLogin = new Date(employee.lastLogin)
  const today = new Date()

  const diffTime = Math.abs(today.getTime() - lastLogin.getTime())
  const daysSinceLastLogin = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1

  const textForLastLogin = `${daysSinceLastLogin} dias`

  let lastLoginColor = '#11181C'

  if (daysSinceLastLogin >= 7 && daysSinceLastLogin <= 13) {
    lastLoginColor = '#AD5700'
  } else if (daysSinceLastLogin >= 14 && daysSinceLastLogin <= 21) {
    lastLoginColor = '#ED5F00'
  } else if (daysSinceLastLogin > 21) {
    lastLoginColor = '#CD2B31'
  }

  return { textForLastLogin, lastLoginColor, daysSinceLastLogin }
}

export const handleConvertCurrencyEnum = (currency: string) => {
  switch (currency) {
    case 'BRL':
      return 0
    case 'USD':
      return 1
    case 'EURO':
      return 2
    default:
      return 0
  }
}

export const handlePeriodicityConvertEnum = (periodicity: string) => {
  switch (periodicity) {
    case 'monthly':
      return 0
    case 'quarterly':
      return 1
    case 'semiannually':
      return 2
    case 'annually':
      return 3

    default:
      return 0
  }
}

export function formatTimeDifference(notificationDate: string): string {
  const notificationDateTime = new Date(notificationDate)
  const currentTime = new Date()
  const timeDifferenceMs =
    currentTime.getTime() - notificationDateTime.getTime()

  const getDifferenceInUnit = (unit: number, unitName: string) => {
    const diff = Math.floor(timeDifferenceMs / unit)
    return diff > 1 ? `${diff} ${unitName}s` : `${diff} ${unitName}`
  }

  if (timeDifferenceMs < 1000) {
    return 'Agora'
  } else if (timeDifferenceMs < 60000) {
    return getDifferenceInUnit(1000, 'segundo') + ' atrás'
  } else if (timeDifferenceMs < 3600000) {
    return getDifferenceInUnit(60000, 'minuto') + ' atrás'
  } else if (timeDifferenceMs < 86400000) {
    return getDifferenceInUnit(3600000, 'hora') + ' atrás'
  } else if (timeDifferenceMs < 2592000000) {
    return getDifferenceInUnit(86400000, 'dia') + ' atrás'
  } else if (timeDifferenceMs < 31536000000) {
    return getDifferenceInUnit(2592000000, 'mesê') + ' atrás'
  } else {
    return getDifferenceInUnit(31536000000, 'ano') + ' atrás'
  }
}

export const phoneMask = (
  // eslint-disable-next-line no-undef
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const input = e.target as HTMLInputElement

  if (input) {
    if (input.value) {
      const inputValue = input.value
      const valueJustNumbers = onlyNumbers(inputValue)
      if (valueJustNumbers.length > 2 && valueJustNumbers.length < 6) {
        input.value = `(${valueJustNumbers.slice(
          0,
          2,
        )}) ${valueJustNumbers.slice(2, 6)}`
      } else if (valueJustNumbers.length > 7) {
        input.value = `(${valueJustNumbers.slice(
          0,
          2,
        )}) ${valueJustNumbers.slice(2, 7)}-${valueJustNumbers.slice(7, 11)}`
      }
    }
  }
  onlyNumbers(input.value)
}

export const formatMoney = (value: string) => {
  return parseFloat(
    value.replace('R$ ', '').replaceAll('.', '').replace(',', '.'),
  )
}

export const parseString = (string: string) => {
  return string.replaceAll('[', '').replaceAll(']', '').replaceAll('"', '')
}

export const firstLetterToUpperCase = (word: string) => {
  if (word) {
    return word[0].toUpperCase() + word.substring(1)
  }
  return ''
}

export const getCloudProvider = (cloudProvider: string) => {
  switch (cloudProvider) {
    case 'aws':
      return 'Amazon Web Services'
    case 'azure':
      return 'Microsoft Azure'
    case 'gcp':
      return 'Google Cloud Platform'
    case 'ibm':
      return 'IBM Cloud'
    case 'digitalocean':
      return 'Digital Ocean'
    case 'linode':
      return 'Linode'
    default:
      return 'N/A'
  }
}

export function subtractDays(dateString: string, days: number): string {
  const dateObject: Date = new Date(dateString)

  dateObject.setDate(dateObject.getDate() - days)

  const resultDate: string = dateObject.toISOString().split('T')[0]

  return resultDate
}

export function removeQueryString(url: string): string {
  const index = url.indexOf('?')
  if (index !== -1) {
    return url.substring(0, index)
  } else {
    return url
  }
}

export function blobToURL(blob: Blob) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}
